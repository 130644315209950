import "../../components/pages/ProjectsPage.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import HeaderProjects from "../../components/sections/HeaderProjects/HeaderProjects.js";
import Layout from "../../components/layout/Layout";
import ProjectCard from "../../components/partials/ProjectCard/ProjectCard.js";
import ProjectTypes from "../../components/sections/ProjectTypes/ProjectTypes.js";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { StyledGrid } from "../../helpers/helpers.js";
import groupBy from "lodash/groupBy";

const getYearFomTimeStamp = (project) =>
  new Date(project.frontmatter.date).getFullYear();

const groupProjectsByYear = (Projects) => {
  return groupBy(Projects, getYearFomTimeStamp);
};

export default ({ data }) => {
  const Projects = data.allMarkdownRemark.nodes;
  const groupedProjects = groupProjectsByYear(Projects);

  return (
    <Layout>
      <StyledGrid className="projects-page">
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Row>
            <HeaderProjects {...data.projectsContent.frontmatter} />
            <Col xs={12} md={7} mdOffset={3}>
              <ProjectTypes />
            </Col>
          </Row>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInBottom"
          duration={1}
          delay={250}
          offset={0}
        >
          {groupedProjects &&
            Object.keys(groupedProjects)
              .reverse()
              .map((key, i) => {
                return (
                  <Row className="project-group" key={i}>
                    <Col xs={12}>
                      <div className="year">{key}</div>
                    </Col>
                    {groupedProjects[key].map(({ frontmatter, fields }, i) => (
                      <Col xs={12} md={6} lg={4} key={i}>
                        <ProjectCard
                          {...frontmatter}
                          {...fields}
                          isInList={true}
                        />
                      </Col>
                    ))}
                  </Row>
                );
              })}
        </ScrollAnimation>
      </StyledGrid>
    </Layout>
  );
};

export const projectsPageQuery = graphql`
  query ProjectsPage {
    projectsContent: markdownRemark(
      frontmatter: { templateKey: { eq: "projects-content" } }
    ) {
      frontmatter {
        title
        highlighted
        description
        featuredImage {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "project-post" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          description
          title
          projects
          name
          featuredPost
          date
          client
          types
          projectImages {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          featuredImage {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
